import React, { useContext, useEffect } from 'react'
import LocaleContext from '../context/LocaleContext'
import PageContext from '../context/PageContext'
import { Locale, localisePath } from '../domain/locale'
import { CONTACT_US_PATH } from '../domain/path'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'

function ContactPage() {
    const { setLocale } = useContext(LocaleContext)

    useEffect(() => {
        setLocale(Locale.Global)
    }, [])

    return (
        <PageContext.Provider
            value={{
                basepath: CONTACT_US_PATH,
                locale: Locale.Global,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title="Contact Us"
                    titleCompany="Telobag Official"
                    description="We are just one message away from making the world more sustainable. Contact us by filling the form or chat with us."
                    relativeUrl={localisePath(CONTACT_US_PATH, Locale.Global)}
                />

                <div className="px-4 mb-6">
                    <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                        Contact Us
                    </h1>
                    <hr className="border-b border-gainsboro w-10" />
                </div>

                <div className="relative bg-white">
                    <div className="absolute inset-0">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
                    </div>
                    <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                        <div className="bg-white py-6 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                            <div className="max-w-lg mx-auto">
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                    Let&#39;s work together
                                </h2>
                                <p className="mt-3 text-lg leading-6 text-gray-500">
                                    Be it about business enquiries, product
                                    feedback or just wanting to share your eco
                                    knowledge, we love to hear from you.
                                </p>
                                <dl className="mt-8 text-base text-gray-500">
                                    <div className="mt-6">
                                        <dt className="sr-only">
                                            Phone number
                                        </dt>
                                        <dd className="flex">
                                            <PhoneIcon
                                                className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <a href="https://wa.me/6285920605928">
                                                <span className="ml-3">
                                                    (+62)85 920 605 928
                                                </span>
                                            </a>
                                        </dd>
                                    </div>
                                    {/* <div className="mt-3">
                                        <dt className="sr-only">Email</dt>
                                        <dd className="flex">
                                            <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" />
                                            <span className="ml-3">
                                                hello@telobag.com.au
                                            </span>
                                        </dd>
                                    </div> */}
                                </dl>
                            </div>
                        </div>
                        <div className="bg-white py-6 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                            <div className="max-w-lg mx-auto lg:max-w-none">
                                <form
                                    name="contact-us"
                                    action="/thanks"
                                    method="POST"
                                    data-netlify="true"
                                    data-netlify-honeypot="bot-field"
                                    className="grid grid-cols-1 gap-y-6"
                                >
                                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                    <input
                                        type="hidden"
                                        name="form-name"
                                        value="contact-us"
                                    />
                                    <p hidden>
                                        <label>
                                            Don’t fill this out:{' '}
                                            <input name="bot-field" />
                                        </label>
                                    </p>

                                    <div>
                                        <label
                                            htmlFor="full-name"
                                            className="sr-only"
                                        >
                                            Full name
                                        </label>
                                        <input
                                            type="text"
                                            name="full-name"
                                            id="full-name"
                                            autoComplete="name"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-ocean-green-500 focus:border-ocean-green-500 border-gray-300 rounded-md"
                                            placeholder="Full name"
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="email"
                                            className="sr-only"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-ocean-green-500 focus:border-ocean-green-500 border-gray-300 rounded-md"
                                            placeholder="Email"
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="phone"
                                            className="sr-only"
                                        >
                                            Phone
                                        </label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            autoComplete="tel"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-ocean-green-500 focus:border-ocean-green-500 border-gray-300 rounded-md"
                                            placeholder="Phone"
                                        />
                                    </div>

                                    <div>
                                        <label
                                            htmlFor="message"
                                            className="sr-only"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            rows={4}
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-ocean-green-500 focus:border-ocean-green-500 border border-gray-300 rounded-md"
                                            placeholder="Message"
                                            defaultValue={''}
                                        />
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-ocean-green-600 hover:bg-ocean-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ocean-green-500"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default ContactPage
